import React from 'react';
import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import LogoLinks from '../components/LogoLinks';

const contact = () => {
   return (
      <Layout>
         <Seo title='Contact' />
         <header className='w-full'>
            <div className='relative'>
               <div className='absolute inset-x-0' />

               <div className='relative h-full'>
                  <div className='absolute inset-0'>
                     <StaticImage
                        src='../images/Contact-Just-A-Nibble-Hero.jpg'
                        quality='100'
                        loading='eager'
                        className='h-full w-full object-cover'
                        alt='Just a Nibble Charcuterie board'
                     />
                     <div aria-hidden='true' className='opacity-70 absolute inset-0 bg-gradient-to-b from-black to-transparent' />
                  </div>
                  <div className='relative mx-auto max-w-7xl px-4 pt-96 text-center sm:px-6 lg:px-8 pb-36'>
                     <h1 className='mt-12 text-5xl font-hanley-script relative sm:text-6xl lg:text-8xl'>
                        <span className='block header'>Contact</span>
                     </h1>
                     <motion.hr
                        initial={{ width: 0, opacity: 0 }}
                        whileInView={{ width: 200, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='border-brand border rounded w-1/2 mx-auto my-3'
                     />
                     <motion.h2
                        initial={{ y: 10, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='text-slate-100 text-base md:text-xl uppercase tagline'
                     >
                        Let’s plan your next event together!
                     </motion.h2>
                  </div>
               </div>
            </div>
         </header>
         <Form />
         <ImageGrid />
         <LogoLinks />
      </Layout>
   );
};

const Form = () => {
   return (
      <section className='relative w-full py-14 overflow-hidden'>
         <div className='absolute inset-0'>
            <StaticImage src='../images/slate.jpg' alt='Slate background' quality='50' className='h-full w-full object-cover' />
            <div aria-hidden='true' className='opacity-60 md:opacity-80 absolute inset-0 bg-black' />
         </div>
         <div className='relative flex items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-White.png'
               className='opacity-50 '
               placeholder='tracedSVG'
               alt='Just a Nibble Utensils Icon'
               quality={100}
               height={125}
            />
            <motion.p
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               transition={{ duration: 1, delay: 1 }}
               viewport={{ once: true }}
               className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'
            >
               Get In Touch
            </motion.p>
         </div>
         <div className='relative py-12'>
            <motion.h3
               initial={{ y: 10, opacity: 0 }}
               whileInView={{ y: 0, opacity: 1 }}
               transition={{ duration: 1 }}
               viewport={{ once: true }}
               className='font-hanley-script text-slate-100 text-center text-5xl'
            >
               Event Inquries
            </motion.h3>
         </div>
         <div className='relative text-sm leading-6 items-center mx-auto justify-center text-slate-200 font-hanley-sans text-center tracking-widest uppercase font-boldest z-50'>
            <p>Nicholas Miller</p>
            <p className='proxima'>(917) 545-8570</p>
            <a className='proxima' href='mailto:Nicholas@justanibblenyc.com'>
               Nicholas@justanibblenyc.com
            </a>
         </div>
         <div className='mt-12 px-8 w-full relative mx-auto max-w-5xl z-50'>
            <form
               name='Just a Nibble'
               method='POST'
               data-netlify='true'
               className='grid gap-y-6 grid-cols-2 gap-x-8'
               action='/success'
               netlify-honeypot='bot-field'
            >
               <input type='hidden' name='form-name' value='Just a Nibble' />
               <div>
                  <p className='hidden'>
                     <label>
                        Don’t fill this out if you’re human: <input name='bot-field' />
                     </label>
                  </p>
                  <label htmlFor='first-name' className='block text-sm   tracking-widest text-slate-300'>
                     First name
                  </label>
                  <div className='mt-1'>
                     <input
                        type='text'
                        name='first-name'
                        id='first-name'
                        autoComplete='given-name'
                        required
                        className='py-3 px-4 block w-full shadow-sm focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                     />
                  </div>
               </div>
               <div>
                  <label htmlFor='last-name' className='block text-sm    tracking-widest text-slate-300'>
                     Last name
                  </label>
                  <div className='mt-1'>
                     <input
                        type='text'
                        name='last-name'
                        id='last-name'
                        autoComplete='family-name'
                        required
                        className='py-3 px-4 block w-full shadow-sm focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                     />
                  </div>
               </div>
               <div className='col-span-2'>
                  <label htmlFor='email' className='block text-sm   tracking-widest text-slate-300'>
                     Email
                  </label>
                  <div className='mt-1'>
                     <input
                        id='email'
                        name='email'
                        type='email'
                        autoComplete='email'
                        required
                        className='py-3 px-4 block w-full shadow-sm focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                     />
                  </div>
               </div>
               <div className='col-span-2'>
                  <label htmlFor='phone-number' className='block text-sm   tracking-widest text-slate-300'>
                     Phone Number
                  </label>
                  <div className='mt-1 relative rounded-md shadow-sm'>
                     <input
                        type='text'
                        name='phone-number'
                        id='phone-number'
                        autoComplete='tel'
                        className='py-3 block w-full px-4 focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                     />
                  </div>
               </div>
               <div className='col-span-1'>
                  <label htmlFor='event-date' className='block text-sm  tracking-widest text-slate-300'>
                     Event Date
                  </label>
                  <div className='mt-1'>
                     <input
                        type='date'
                        id='event-date'
                        name='event-date'
                        min='2022-08-22'
                        max='2030-08-22'
                        className='px-4 py-2 block w-full focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                     />
                  </div>
               </div>
               <div className='col-span-1'>
                  <div className='w-full flex flex-row items-center justify-start gap-4'>
                     <div>
                        <label for='time-from' htmlFor='time-from' className='block text-sm  tracking-widest text-slate-300'>
                           From:
                        </label>
                        <select
                           name='time-from'
                           id='time-from'
                           required
                           className='mt-1 px-4 py-3 block w-full focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                        >
                           <option disabled selected value>
                              -- select an option --
                           </option>
                           <option value='12 AM'>12:00 AM</option>
                           <option value='1 AM'>1:00 AM</option>
                           <option value='2 AM'>2:00 AM</option>
                           <option value='3 AM'>3:00 AM</option>
                           <option value='4 AM'>4:00 AM</option>
                           <option value='5 AM'>5:00 AM</option>
                           <option value='6 AM'>6:00 AM</option>
                           <option value='7 AM'>7:00 AM</option>
                           <option value='8 AM'>8:00 AM</option>
                           <option value='9 AM'>9:00 AM</option>
                           <option value='10 AM'>10:00 AM</option>
                           <option value='11 AM'>11:00 AM</option>
                           <option value='12 PM'>12:00 PM</option>
                           <option value='1 PM'>1:00 PM</option>
                           <option value='2 PM'>2:00 PM</option>
                           <option value='3 PM'>3:00 PM</option>
                           <option value='4 PM'>4:00 PM</option>
                           <option value='5 PM'>5:00 PM</option>
                           <option value='6 PM'>6:00 PM</option>
                           <option value='7 PM'>7:00 PM</option>
                           <option value='8 PM'>8:00 PM</option>
                           <option value='9 PM'>9:00 PM</option>
                           <option value='10 PM'>10:00 PM</option>
                           <option value='11 PM'>11:00 PM</option>
                        </select>
                     </div>
                     <div>
                        <label for='time-to' htmlFor='time-to' className='block text-sm  tracking-widest text-slate-300'>
                           To:
                        </label>
                        <select
                           name='time-to'
                           id='time-to'
                           required
                           className='mt-1 px-4 py-3 block w-full focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                        >
                           <option disabled selected value>
                              -- select an option --
                           </option>
                           <option value='12 AM'>12:00 AM</option>
                           <option value='1 AM'>1:00 AM</option>
                           <option value='2 AM'>2:00 AM</option>
                           <option value='3 AM'>3:00 AM</option>
                           <option value='4 AM'>4:00 AM</option>
                           <option value='5 AM'>5:00 AM</option>
                           <option value='6 AM'>6:00 AM</option>
                           <option value='7 AM'>7:00 AM</option>
                           <option value='8 AM'>8:00 AM</option>
                           <option value='9 AM'>9:00 AM</option>
                           <option value='10 AM'>10:00 AM</option>
                           <option value='11 AM'>11:00 AM</option>
                           <option value='12 PM'>12:00 PM</option>
                           <option value='1 PM'>1:00 PM</option>
                           <option value='2 PM'>2:00 PM</option>
                           <option value='3 PM'>3:00 PM</option>
                           <option value='4 PM'>4:00 PM</option>
                           <option value='5 PM'>5:00 PM</option>
                           <option value='6 PM'>6:00 PM</option>
                           <option value='7 PM'>7:00 PM</option>
                           <option value='8 PM'>8:00 PM</option>
                           <option value='9 PM'>9:00 PM</option>
                           <option value='10 PM'>10:00 PM</option>
                           <option value='11 PM'>11:00 PM</option>
                        </select>
                     </div>
                  </div>
               </div>

               <div className='col-span-2'>
                  <label htmlFor='event' className='block text-sm   tracking-widest text-slate-300'>
                     Event Type
                  </label>
                  <div className='mt-1 w-full'>
                     <select
                        id='event'
                        name='event'
                        rows={4}
                        required
                        className='py-3 px-4 block w-full shadow-sm focus:ring-brand focus:border-brand border border-gray-300 rounded-md'
                        defaultValue={'Event Type'}
                     >
                        <option value='0' selected='selected'>
                           Choose...
                        </option>
                        <option value='Wedding'>Wedding</option>
                        <option value='Birthday Party'>Birthday Party</option>
                        <option value='Baby Shower'>Baby Shower</option>
                        <option value='Wedding Shower'>Wedding Shower</option>
                        <option value='Cocktail Party'>Cocktail Party</option>
                        <option value='Fundraisers'>Fundraisers</option>
                        <option value='Business Launches'>Business Launch</option>
                        <option value='Memorials'>Memorial</option>
                        <option value='Private Dinner Party'>Private Dinner Party</option>
                        <option value='Other'>Other</option>
                     </select>
                  </div>
               </div>
               <div className='col-span-2'>
                  <label htmlFor='guest-amount' className='block text-sm tracking-widest text-slate-300'>
                     Number of Guests
                  </label>
                  <div className='mt-1'>
                     <input
                        type='number'
                        name='guest-amount'
                        id='guest-amount'
                        min='10'
                        max='500'
                        className='py-3 px-4 block w-full shadow-sm focus:ring-brand focus:border-brand border-gray-300 rounded-md'
                     />
                  </div>
               </div>
               <div className='col-span-2'>
                  <button
                     type='submit'
                     className='hover:bg-brand-light w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base   tracking-widest text-slate-100 bg-brand focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand'
                  >
                     Submit
                  </button>
               </div>
            </form>
         </div>
         <motion.hr
            initial={{ width: 0, opacity: 0 }}
            whileInView={{ width: 200, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className='border-brand border rounded relative z-50 mx-auto mt-12'
         />
      </section>
   );
};

const ImageGrid = () => {
   return (
      <section className='relative'>
         <div className='grid-cols-2 sm:grid-cols-4 grid w-full'>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  alt='Crostini appetizer with cheese and jam'
                  src='../images/Contact-Just-A-Nibble-StaffImg1.jpg'
                  objectPosition='50% 0%'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  alt='Crostini appetizer with steak'
                  src='../images/Contact-Just-A-Nibble-StaffImg2.jpg'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  alt='Pulled pork slider appetizer'
                  src='../images/Contact-Just-A-Nibble-StaffImg3.jpg'
                  objectPosition='50% 75%'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  alt='Lobster roll appetizer'
                  src='../images/Contact-Just-A-Nibble-StaffImg4.jpg'
                  objectPosition='50% 85%'
               />
            </div>
         </div>
         <div aria-hidden='true' className='opacity-20 absolute inset-0 bg-black' />
      </section>
   );
};

export default contact;
